.iPopup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  left: 0;
  height: 100%;
  background: rgba(57, 54, 79, 0.8);
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 1301;
}

.videoContainer {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 0 15px;
  position: relative;
}

.close {
  position: absolute;
  right: 5px;
  top: -12px;
  width: 31px;
  background: #fff;
  padding: 6px;
  cursor: pointer;
  box-shadow: 0 20px 40px 0 rgba(255, 90, 54, 0.6);
}

.videoContainer iframe {
  height: 100%;
  min-height: 220px;
  box-shadow: 0 20px 40px 0 rgba(255, 90, 54, 0.16);
}

@media (min-width: 578px) {
  .videoContainer iframe {
    height: 100%;
    min-height: 545px;
  }
}