.iPopup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: grid;
  background: rgba(255, 255, 255, 0.85);
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 100;
  overflow-y: auto;
}

.popupSliderContainer {
  margin: 18px;
  /* overflow: hidden; */
}

.popupSliderContainer > div.stepsContainer {
  width: 300%;
  display: grid;
  grid-template-columns: repeat(3, 33.33333333333333%);
  transition: transform 0.5s;
}

.popupSliderContainer.step1 > div.stepsContainer {
  transform: translateX(0);
}

.popupSliderContainer.step2 > div.stepsContainer {
  transform: translateX(-33.33333333333333%);
}

.popupSliderContainer.step3 > div.stepsContainer {
  transform: translateX(-66.66666666666666%);
}

.popinput {
  width: 100%;
  padding-left: 20px;
  height: 45px;
  border-radius: 6px;
  border: solid 1px #c7c6cb;
  background-color: #f5f5f5;
  margin-top: 19px;
}

.iPopup .welcomeContainer {
  max-width: 560px;
  border-radius: 4px;
  box-shadow: 0 20px 40px 0 rgba(255, 90, 54, 0.16);
  border: solid 1px #c7c6cb;
  background-color: #ffffff;
  width: 85%;
  margin: auto;
  position: relative;
  border-radius: 12px;
}

.popupFooter {
  padding: 30px;
  max-width: 380px;
  margin: auto;
}

.processContainer {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  /* grid-gap: 48px; */
  justify-content: center;
  align-items: center;
}

.processContainer.justifyCenter {
  grid-template-columns: auto;
  justify-content: center;
}

/* completed process */
.header.completed p {
  margin: 55px 0 40px;
}

.processContainer.completed {
  justify-content: center;
  grid-template-columns: auto;
}

/* completed process end */

.dotsContainer {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 14px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c7c6cb;
  background-color: #ffffff;
  cursor: pointer;
}

.dot.active {
  background-color: #7f56d9;
  border: solid 1px #7f56d9;
}

.skipLink {
  color: #9f9ea3;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
}

.skipLink img {
  transform: rotate(-90deg);
}

.header h3 {
  padding: 0 15px;
}

.iPopup .welcomeContainer > div form input[type='submit'],
.iPopup .welcomeContainer > div form input[type='button'] {
  width: 100%;
  border-radius: 4px;
  color: #fff;
  border-color: transparent;
  font-size: 15px;
  padding: 13px 0;
}

.iPopup .welcomeContainer > div form input[type='email'] {
  background-image: url('https://lh3.googleusercontent.com/proxy/x1hePiPzmy4urS7dcsN2u4vTQuCoMuww7uRMqPb_bhR9g1ihE4a8A1nRy_09SOg0zLqmyPUP4JdIuvonunEAAn2hnZq7FZGidpw2zwzHuFRIEFF6msoNig');
  background-size: 19px;
  background-position: right 11px bottom 13px;
  background-repeat: no-repeat;
}

.iPopup .welcomeContainer > div form input[type='submit'],
.iPopup .welcomeContainer > div form input[type='button'] {
  background-color: #7f56d9;
  margin-top: 26px;
}

.header p {
  margin: 11px auto 25px;
  width: 100%;
  color: #000;
}

.header h3 {
  margin-top: 8px;
  color: #000;
}

input.popinput {
  outline: none;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: darkgrey;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

::placeholder.popinput {
  color: #9f9ea3;
}

@media (min-width: 568px) {
  .header p {
    margin: 17px auto 45px;
  }

  /* .processContainer {
    grid-gap: 77px;
  } */
  .dotsContainer {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 24px;
  }

  .dot {
    width: 20px;
    height: 20px;
  }

  .skipLink {
    font-size: 18px;
  }

  .skipLink img {
    width: 28px;
  }

  .iPopup .welcomeContainer > div form input[type='submit'],
  .iPopup .welcomeContainer > div form input[type='button'] {
    font-size: 18px;
    padding: 20px 0;
  }
}

@media (min-width: 1200px) {
  .iPopup .welcomeContainer {
    max-width: 520px;
  }

  .header p {
    margin: 17px auto 60px;
  }

  .header h3 {
    padding: 0;
  }
}
